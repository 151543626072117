import React from 'react';
import { EditableArea } from '@magnolia/react-editor';

import { getImageUrl } from 'utils/magnolia';

function Section(props) {
  const {
    backgroundColor,
    textColor,
    height = "auto",
    backgroundImage,
    columns = "4",
    isParallaxScrolling,
    removePaddingBottom,
    removePaddingTop,
  } = props;
  const columnsToRender = [];
  let className = 'Section cover';
  let style = {};

  if (backgroundColor) style.backgroundColor = backgroundColor;
  if (textColor) style.color = textColor;
  if (height) style.height = "auto";
  if (backgroundImage) style.backgroundImage = `url(${getImageUrl(backgroundImage)})`;
  if (isParallaxScrolling === true) style.backgroundAttachment = 'fixed';
  if (removePaddingBottom === true) style.paddingBottom = '0';
  if (removePaddingTop === true) style.paddingTop = '0';

  if (columns.split(' ').length > 1) {
    for (let i = 1; i <= columns.split(' ').length; i++) {
      renderColumn(i, `col--${columns.split(' ')[i - 1]}`);
    }
  } else {
    for (let i = 1; i <= columns; i++) {
      renderColumn(i, `col--1-${columns}`);
    }
  }

  function renderColumn(i, cssClass) {
    const colId = 'col' + i;

    if (props[colId]) {
      columnsToRender.push(
        <div key={props[colId]['@id']} className={`col ${cssClass}`}>
          <EditableArea content={props[colId]} parentTemplateId={props.metadata['mgnl:template']} />
        </div>
      );
    }
  }

  return (
    <div className={className} style={style}>
      {columnsToRender.length > 0 && <div className='wrapper text-center '>{columnsToRender}</div>}
    </div>
  );
}

export default Section;
